import {useEffect} from "react";
import {inject, observer} from "mobx-react"
import {toJS} from "mobx";
import {Badge, Box, Button, Chip, Grid, Link} from "@mui/material";
import Table from "../components/Table";
import TradingviewLink from "../components/TradingviewLink";
import HomepageUrl from "../components/HomepageUrl";
import _get from "lodash/get";
import {getEndpoint} from "../communication/rest";


export default ({stockdata}) => {

  const compareCount = (l, r) => r.count - l.count

  const toTreeElement = (d) => ({
    sector : d.stock.gicsSector,
    industry : d.stock.gicsIndustry
  })

  const treeReducer = (acc, item ) => {
    if (item.sector?.value) {

      const sector = acc.find(e => e.value === item.sector.value)

      if (sector == null) {
        // initial value
        acc.push({
          count: 1,
          name: item.sector.finvizName,
          value: item.sector.value,
          industries : [{
            count: 1,
            name: item.industry?.finvizName,
            value: item.industry?.value
          }]
        })

      } else {

        sector.count += 1

        if (item.industry?.value) {
          const industry = sector.industries?.find(i => i.value === item.industry.value)
          if (!industry) {
            sector.industries.push({
              count: 1,
              name: item.industry?.finvizName,
              value: item.industry?.value
            })
          } else {
            industry.count += 1
          }
        }
      }
    }
    return acc
  }
  const buildTree = data => {
    const tree = data.map(toTreeElement).reduce(treeReducer, []).sort(compareCount)
    tree.forEach(e => e.industries.sort(compareCount))
    return tree
  }

  const tree = buildTree(stockdata)

  const columns = [
    {
      field: 'count',
      headerName: "Anzahl",
      width: 50
    },
    {
      field: 'name',
      headerName: "Sector",
      width: 100
    },
    {
      field: 'industries',
      headerName : "Industrien",
      renderRowCell: (column, row) => {
        return row[column.field].slice(0, 10).map(i => (
          <Badge badgeContent={i.count} color="success" key={i.value}>
            <Chip label={i.name} variant="outlined" size="small" sx={{marginLeft: '1em', marginBottom: '1em'}}/>
          </Badge>
        ))
      }
    }
  ]

  return (
    <div>
      <Table
        columns={columns}
        rows={tree}
      />
    </div>
  )
}

