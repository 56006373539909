import {Dialog, Button, DialogTitle, DialogContent, DialogActions, Grid} from "@mui/material";
import {Form, Formik} from "formik";
import TextField from "../components/TextField";
import {validateNumber} from "../util/Validations";
import * as yup from 'yup';
import {inject, observer} from "mobx-react";
import DateTimeField from "../components/DateTimeField";
import NumberField from "../components/NumberField";
import AutocompleteField from "../components/AutocompleteField";
import {INTEGER_FORMAT} from "../util/Numbers";
import {useEffect} from "react";
import {toJS} from "mobx";

export default inject("tradeStore", "stockListStore")(observer(({show, onCancel, onSuccess, initialValues, tradeStore, stockListStore}) => {

  useEffect(() => {
    stockListStore.list()
  }, []);

  if (!initialValues) {
    return null;
  }

  return (
    <Formik
      validationSchema={yup.object().shape({
        symbol: yup.string().required(),
        executions: yup.array().of(yup.object().shape({
          executionDate: yup.string().required(),
          price: validateNumber().required(),
          quantity: validateNumber().required(),
          comment: yup.string().max(1024).nullable()
        }))
      })}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(trade) => {
        tradeStore.save(trade, (savedTrade) => {
          onSuccess(savedTrade)
        })
      }}
    >
      <Dialog open={show} onClose={onCancel}>

        <Form>
          <DialogTitle onClick={onCancel}>Trade</DialogTitle>

          <DialogContent>
            <Grid container spacing={3}>

              <Grid item sm={2}>
                <TextField
                  label="Side"
                  name="direction"
                  disabled={true}
                />
              </Grid>

              <Grid item sm={5}>
                <AutocompleteField
                  label="Sym"
                  name="symbol"
                  freeSolo
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  getOptionLabel={(option) => option.symbol}
                  options={toJS(stockListStore.stocks)}
                />
              </Grid>

              <Grid item sm={5}>
                <DateTimeField
                  label="Date"
                  name="executions[0].executionDate"
                />
              </Grid>

              <Grid item sm={2}>
                <NumberField
                  format={INTEGER_FORMAT}
                  label="Qty"
                  name="executions[0].quantity" />

              </Grid>

              <Grid item sm={5}>
                <NumberField
                  label="Fill"
                  name="executions[0].price" />
              </Grid>

              <Grid item sm={5}>
                <NumberField
                  label="Initial Stop"
                  name="executions[0].initialStop" />
              </Grid>

              <Grid item sm={2}>
                <AutocompleteField
                  label="Stage"
                  name="stage"
                  getValue={(option) => option}
                  options={[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]}
                />
              </Grid>

              <Grid item sm={5}>
                <AutocompleteField
                  label="Pattern"
                  name="buyPattern"
                  getValue={(option) => option}
                  options={['T', 'C3', 'W', 'PP']}
                />
              </Grid>

              <Grid item sm={5}>
                <TextField
                  label="Pattern Description"
                  name="buyPatternInfo"
                  maxLength={100}
                />
              </Grid>

              <Grid item sm={12}>
                <TextField
                  label="Comment"
                  name="executions[0].comment"
                  multiline
                  rows={3}
                  maxLength={1024}
                  fullWidth
                />
              </Grid>


            </Grid>

          </DialogContent>

          <DialogActions>
            <Button onClick={onCancel}>Close</Button>
            <Button type="submit">Save</Button>
          </DialogActions>
        </Form>
      </Dialog>
    </Formik>
  )
}))

