import axios from "axios";
import {toast} from "react-toastify";
import ToastMessage from "../components/ToastMessage";

let sessionStore = null

let baseUrl = process.env.NODE_ENV === 'production' ? 'http://ts.florissen.de' : 'http://localhost'

let endpoint = `${baseUrl}:8080/ts/api/`
let client = axios.create({
  baseURL: endpoint,
  timeout: 30000,
  withCredentials: true                            // enable set-cookie
});

client.interceptors.response.use(function (response) {
  return response;
}, function (error) {

  if (error.response?.status === 403) {

    sessionStore.refresh()
  } else if (error.response?.status === 401) {
    if (!sessionStore.loading) {
      sessionStore.logout(() => {
        toast.error("Session expired. Please login again.")
      })
    } else {
      sessionStore.logout()
    }
  } else if (error.message === 'Network Error') {
    sessionStore.logout()
  } else if (error.response.data) {
    const title = error.response?.data?.message;
    const message = error.response?.data?.info;
    toast.error(<ToastMessage title={title} message={message} />)
  }

  return Promise.reject(error);
});

export function init(store) {
  // this is a small hack... if we do not set a fake object here
  // the application will render the login page immediatly,
  // because the session is null
  sessionStore = store
  sessionStore.refresh()
}

export function getEndpoint() {
  return endpoint;
}

export default client;



