import {flow, makeObservable, observable, action} from "mobx"
import axios from '../communication/rest'

export default class TransactionStore {

  transaction = null;

  constructor() {
    makeObservable(this, {
      transaction: observable,
      save: flow,
      set: action
    })
  }

  *save(bankId, transaction, callback = () => null) {
    const response = yield axios.post(`banks/${bankId}/transactions`, transaction);
    this.transaction = response.data;
    callback(this.transaction)
  }

  set(transaction) {
    this.transaction = transaction
  }

}