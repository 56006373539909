import {useField} from "formik"
import {Checkbox as MaterialCheckbox, FormControlLabel} from "@mui/material";

const CheckboxField = ({type, label, name, ...props}) => {

  const [field, meta, helpers] = useField({name});

  const handleChange = (e, newValue) => {
    helpers.setValue(newValue)
  }

  return (
    <FormControlLabel
      control={
        <MaterialCheckbox
          {...field}
          {...props}
          error={meta.touched && meta.error}
          helperText={meta.error}
          autoComplete={"off"}
          variant={"standard"}
          id={name}
          checked={field.value}
          onChange={handleChange}
        />
      }

      label={label}
    />
  )
}

export default CheckboxField;