import React from 'react';
import * as ReactDOM from 'react-dom/client';
import Root from './Root';
import reportWebVitals from './reportWebVitals';
import {CssBaseline} from "@mui/material";

const root = document.getElementById("root")

ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <CssBaseline />
    <Root />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
