import {DateTimePicker} from "@mui/x-date-pickers";
import {TextField} from "@mui/material";
import {useField} from "formik";
import {useRef} from "react";


const DateTimeField = ({id, label, className, name}) => {

  const inputRef = useRef()

  const US_DATE_FORMAT = /(\s*)(\d{2})\/(\d{2})\/(\d{4})(\s*)/g

  const [field, meta, helpers] = useField({name});

  const {onBlur, value} = field

  const onPaste = (e) => {
    let content = e.clipboardData.getData("text")
    if (content && US_DATE_FORMAT.test(content)) {
      e.preventDefault()
      e.stopPropagation()

      let newDate = content.replaceAll(US_DATE_FORMAT, '$4-$2-$3T02:00:00.000Z')

      inputRef.current.blur()

      // HACK: We have to blur the current field and then set the field value
      // if the focus is within the field, the field update will not reflect the changes
      // seems like a bug
      setTimeout(() => {
        helpers.setValue(newDate);
      }, 100)


    }
  }

  return (
    <DateTimePicker
      ampm={false}
      inputFormat={"dd.MM.yyyy HH:mm"}
      mask={"__.__.____ __:__"}
      label={label}
      onChange={(v ) => {
        if (v && v !== 'Invalid Date') {
          try {
            helpers.setValue(v.toISOString());
          } catch (e) {

          }
        } else {
          helpers.setValue(null)
        }
      }}
      value={value}
      renderInput={(params) => {
        return <TextField
          {...params}
          className={className}
          id={id}
          name={name}
          type="text"
          variant="standard"
          onBlur={onBlur}
          error={meta.touched && meta.error}
          helperText={meta.touched && meta.error}
          onPaste={onPaste}
          inputRef={inputRef}
          />
      }}
    />
  )
}

export default DateTimeField;