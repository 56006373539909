import {BrowserRouter} from "react-router-dom";
import {Provider} from 'mobx-react'

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from "@mui/x-date-pickers";
import "typeface-roboto";
import GlobalStore from "./GlobalStore";
import App from "./App";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {init as initRest} from "./communication/rest"
import {init as initWebsocket} from "./communication/websockets"

initRest(GlobalStore.sessionStore)
initWebsocket(GlobalStore.sessionStore)

function Root() {

  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Provider {...GlobalStore}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

          <App />
        </Provider>
      </LocalizationProvider>
    </BrowserRouter>
  );
}

export default Root;
