import {Client} from "@stomp/stompjs"
import * as SockJS from "sockjs-client"
// Try to set up WebSocket connection with the handshake at "http://localhost:8080/stomp"

let client = null;

const PREFIX = "/ws";

let sessionStore = null

let baseUrl = process.env.NODE_ENV === 'production' ? 'ts.florissen.de' : 'localhost'

let subscriptions = []

function awaitSession(callback) {
  setTimeout(
    function () {
      if (sessionStore && sessionStore.isValid()) {
        if (callback) callback()
      } else {
        awaitSession(callback);
      }

    }, 250);
}

export function subscribe(channel, callback) {
  subscriptions.push({channel, callback})
}

export function disconnect() {
  if (client) {
    client.deactivate()
  }
}

export function activate() {
  if (client) {
    client.activate();
  }
}

export function init(session) {
  sessionStore = session
  awaitSession(() => {
    client = new Client({
      brokerURL: "ws://" + baseUrl + ":8080/ts/stomp",
      debug: function (str) {
        console.log(str);
      },
      onConnect: () => {
        subscriptions.forEach(({channel, callback}) => {
          client.subscribe(PREFIX + channel, (frame) => {
            console.log("incoming message " + frame)
            callback(JSON.parse(frame.body));
          });
        })
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000
    })

    client.webSocketFactory= function () {
      return new SockJS("http://" + baseUrl + ":8080/ts/stomp",);
    };

  })
}




