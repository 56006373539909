import {flow, makeObservable, observable} from "mobx"
import axios from '../communication/rest'

export default class StockDataStore {

  stockdata = [];

  constructor() {
    makeObservable(this, {
      stockdata: observable,
      updateRs: flow,
      listStocksByRs: flow,
      listSectorsByRs: flow,
      listUptrendingStocks: flow
    })
  }

  *listStocksByRs(format = 'tradingview') {
    const response = yield axios.get("stockdata/rs/stocks", {
      params : {
        format
      }
    });
    this.stockdata = response.data;
  }

  *updateRs() {
    yield axios.post("stockdata/rs/update")
  }

  *listSectorsByRs() {
    const response = yield axios.get("stockdata/rs/sectors");
    this.stockdata = response.data;
  }

  *listUptrendingStocks() {
    const response = yield axios.get("stockdata/uptrending");
    this.stockdata = response.data;
  }

}