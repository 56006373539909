import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import {Form, Formik} from "formik";
import TextField from "../components/TextField";
import * as yup from 'yup';
import {inject, observer} from "mobx-react";
import AutocompleteField from "../components/AutocompleteField";

export default inject("tagStore")(observer(({tagId, show, onCancel, onSuccess, initialValues, tagStore}) => {

  if (!initialValues) {
    return null;
  }

  return (
    <Formik
      validationSchema={yup.object().shape({
        evaluationType: yup.string().required(),
        abbreviation: yup.string().max(6).required(),
        description: yup.string().max(512).required()
      })}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(tag) => {
        tagStore.save(tag, (newTag) => {
          onSuccess(newTag)
        })
      }}
    >
      <Dialog open={show} onClose={onCancel}>

        <Form>
          <DialogTitle onClick={onCancel}>Tag</DialogTitle>

          <DialogContent>
            <Grid container spacing={3}>

              <Grid item sm={5}>
                <AutocompleteField
                  label="Evaluation"
                  name="evaluationType"
                  getValue={(option) => option}
                  options={['WORST', 'WORSE', 'BAD', 'NEUTRAL', 'GOOD', 'BETTER', 'BEST']}
                />
              </Grid>

              <Grid item sm={5}>
                <TextField
                  label="abbreviation"
                  name="abbreviation"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                />
              </Grid>

              <Grid item sm={5}>
                <TextField
                  label="Description"
                  name="description"
                />
              </Grid>

            </Grid>

          </DialogContent>

          <DialogActions>
            <Button onClick={onCancel}>Close</Button>
            <Button type="submit">Save</Button>
          </DialogActions>
        </Form>
      </Dialog>
    </Formik>
  )
}))

