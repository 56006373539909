import {Link} from "@mui/material";
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

const HomepageUrl = ({url}) => {

  if (!url) {
    return null
  }

  return <Link rel="noreferrer" href={url} target="_blank" title={url}>
    <OpenInNewOutlinedIcon fontSize="inherit" />
  </Link>
}

export default HomepageUrl;