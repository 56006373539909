import {flow, makeObservable, observable} from "mobx"
import axios from '../communication/rest'
import {toast} from "react-toastify";
import {activate, disconnect} from "../communication/websockets";

export default class SessionStore {

  lastCredentials = null;
  session = null;
  loading = true;
  callbacks = [];

  constructor() {
    makeObservable(this, {
      session: observable,
      loading: observable,
      login: flow,
      refresh: flow,
      logout: flow
    })
  }

  *login(credentials) {

    credentials.rememberMe = !!credentials.rememberMe
    const response = yield axios
      .post(`session`, credentials).catch(error => {
        return error.response
      })

    if (response?.status === 200) {
      activate();
      toast.success("Yeah... let's go")
      this.session = response.data
    } else {
      disconnect();
      toast.error("Ups... try again")
      this.lastCredentials = credentials
      this.session = null
    }
    this.loading = false
  }

  *refresh() {
    const response = yield axios.get(`session`)
    if (response?.status === 200) {
      activate();
    } else {
      disconnect();
    }
    this.session = response.data
    this.loading = false
  }

  isValid() {
    return this.session && this.session.username
  }

  *logout(callback) {
    disconnect();
    yield axios.delete(`session`)

    this.session = null
    this.loading = false

    if (callback) {
      callback()
    }
  }

  doAfterLogin(callback) {
    this.callbacks.push(callback)
  }
}