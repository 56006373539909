import {inject, observer} from "mobx-react"
import {Button, Grid} from "@mui/material";
import {Form, Formik} from "formik";
import TextField from "../components/TextField";
import {toJSON} from "lodash/seq";


export default inject(["tradeListStore"])(observer(({tradeListStore, ...props}) => {

  let query = toJSON(tradeListStore.query)
  return <>
    <Formik
      initialValues={query || {}}
      enableReinitialize={true}
      onSubmit={(tradeQuery) => {
        tradeListStore.search(tradeQuery)
      }}
    >
      <Form>
        <Grid container spacing={5}>
          <Grid item sm={2}>
            <TextField
              label="Sym"
              name="symbol"
            />
          </Grid>
          <Grid item sm={8}>
            <Button type="submit">Search</Button>
          </Grid>
        </Grid>
        <br />
      </Form>
    </Formik>
  </>
}))

