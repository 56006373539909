import {useField} from "formik"
import {TextField as MaterialTextField} from "@mui/material";

const TextField = ({type, name, ...props}) => {

  const [field, meta] = useField({name, type : type ? type : 'text'});

  return (
    <MaterialTextField
      {...field}
      {...props}
      value={field.value || ""}
      error={meta.touched && meta.error}
      helperText={meta.touched && meta.error}
      autoComplete={"off"}
      variant={"standard"}
      id={name}
      type={type}
    />
)
}

export default TextField;