import {flow, makeObservable, observable} from "mobx"
import axios from '../communication/rest'

export default class TransactionListStore {

  transactions = [];

  page = null

  pageable = {}

  constructor() {
    makeObservable(this, {
      transactions: observable,
      list: flow,
      changePage: flow
    })
  }

  *list(bankId) {
    const response = yield axios.get(`/banks/${bankId}/transactions`, {
      params : {
        ...this.pageable
      }
    });
    this.transactions = response.data.content
    delete response.data.content
    this.page = response.data
  }

  *changePage(pageable, options) {
    this.pageable = pageable;
    yield this.list(options.bankId);
  }
}