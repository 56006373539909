import {useNavigate} from "react-router-dom";
import {AppBar, Box, Button, IconButton, Menu, MenuItem, Toolbar, Typography} from "@mui/material";
import NewTradeButton from "../components/NewTradeButton";
import {inject, observer} from "mobx-react";
import {AccountCircle} from "@mui/icons-material";
import {useState} from "react";
import {toast} from "react-toastify";

export default inject("sessionStore")(observer(({sessionStore}) => {

  const [menuOpen, setMenuOpen] = useState(false)
  const navigate = useNavigate();

  const handleSuccess = (trade) => {
    navigate("/trades/" + trade.id);
  }

  const handleLogout = () => {
    sessionStore.logout(() => {
      toast.success("Good bye!")
    })
  }

  return (
    <Box xs={{flexGrow: 1}}>
      <AppBar position="static">

        <Toolbar>

          <Typography variant="h6" sx={{ flex: 1 }} onClick={() => navigate("/")}>
            TradeStat&nbsp;&nbsp;&nbsp;

            <NewTradeButton direction="LONG" onSuccess={handleSuccess}/>
            &nbsp;&nbsp;
            <NewTradeButton direction="SHORT" onSuccess={handleSuccess}/>
          </Typography>

          <div>
            <Button color="inherit" onClick={() => navigate("/summary")}>Summary</Button>
            <Button color="inherit" onClick={() => navigate("/tags")}>Tags</Button>
            <Button color="inherit" onClick={() => navigate("/transactions")}>Transactions</Button>
            <Button color="inherit" onClick={() => navigate("/stocks")}>Stocks</Button>
            <Button color="inherit" onClick={() => navigate("/rs/stocks")}>RS Stocks</Button>
            <Button color="inherit" onClick={() => navigate("/rs/sectors")}>RS Sectors</Button>
            <Button color="inherit" onClick={() => navigate("/uptrending")}>Trending</Button>
            <Button color="inherit" onClick={() => navigate("/trades")}>Trades</Button>
          </div>

          <div>
            <IconButton
              size="large"
              onClick={() => setMenuOpen(true)}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              open={menuOpen}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={() => setMenuOpen(false)}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}))


