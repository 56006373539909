import {flow, makeObservable, observable} from "mobx"
import axios from '../communication/rest'

export default class TagListStore {

  tags = [];

  page = null

  pageable = {}

  constructor() {
    makeObservable(this, {
      tags: observable,
      list: flow,
      changePage: flow
    })
  }

  *list() {
    const response = yield axios.get("tags", {
      params : {
        ...this.pageable
      }
    });
    this.tags = response.data.content;
    delete response.data.content
    this.page = response.data
  }

  listAll(callback) {
    axios.get("tags", {
      params : {
        size: 999999
      }
    }).then(response => {
      callback(response.data.content)
    });
  }

  *changePage(pageable) {
    this.pageable = pageable;
    yield this.list();
  }
}