import {action, flow, makeObservable, observable} from "mobx"
import {subscribe} from '../communication/websockets'
import axios from "../communication/rest";

export default class SummaryStore {

  summary = null;

  constructor() {

    makeObservable(this, {
      summary: observable,
      get: flow,
      set: action
    })

    subscribe("/summary", this.set.bind(this))
  }


  set(summary) {
    this.summary = summary;
  }

  *get() {
    const response = yield axios.get("summary");
    this.summary = response.data;
  }

}