import {useEffect} from "react";
import {inject, observer} from "mobx-react"
import {toJS} from "mobx";
import {Box, Button, Grid} from "@mui/material";
import Table from "../components/Table";
import Pagination from "../components/Pagination";
import TagAddModal from "../tag/TagAddModal";
import TagChip from "../components/TagChip";


export default inject("tagListStore", "tagStore")(observer(({tagListStore, tagStore}) => {

  useEffect(() => {
    tagListStore.list()
  }, []);

  const columns = [
    {
      field: 'abbreviation',
      headerName : "Abbreviation",
      renderRowCell : (column, row) => <TagChip tag={row} />
    },
    {
      width: '100%',
      field: 'description',
      headerName : "Description"
    }
  ]

  const clearTag = () => {
    setTimeout(() => {
      tagStore.set(null)
      tagListStore.list()
    }, 100)

  }

  const addTag = (e) => {
    tagStore.set({})
  }

  const handleRowClick = (tag) => {
    tagStore.set(tag)
  }


  return (

    <Grid>

      <h2>Tags</h2>

      <Pagination store={tagListStore}/>

      <Table
        onRowClick={handleRowClick}
        columns={columns}
        rows={toJS(tagListStore.tags)}
      />

      <Box justify="flex-end">
        <Button variant="outlined" onClick={addTag}>ADD</Button>
      </Box>

      {tagStore.tag &&
      <TagAddModal
        show={true}
        onSuccess={clearTag}
        tagId={tagStore.tag.id}
        onCancel={clearTag}
        initialValues={tagStore.tag}
      />
      }

    </Grid>
  )
}))

