import {Button} from "@mui/material";
import TradeAddTradeModal from "../trade/TradeAddTradeModal";
import {useState} from "react";


const NewTradeButton = ({direction, onSuccess}) => {

  const [trade, setTrade] = useState(null);

  const handleClose = () => {
    setTrade(null)
  }

  const handleSuccess = (newTrade) => {
    setTrade(null);
    onSuccess(newTrade)
  }

  const handleClick = (execution) => {
    setTrade({
      direction: direction,
      executions: [{
        executionDate: new Date().toISOString(),
        type: direction === 'LONG' ? 'BUY' : 'SELL',
        quantity: 1
      }],
    })
  }

  return (
    <>
      <Button color="inherit" variant="outlined" onClick={handleClick}>{direction}</Button>

      {trade &&
      <TradeAddTradeModal
        show={true}
        onSuccess={handleSuccess}
        onCancel={handleClose}
        initialValues={trade}
      />
      }
    </>
  )
}

export default NewTradeButton;
