import {Chip, Tooltip} from "@mui/material";
import useTagColor from "./useTagColor";

const TagChip = ({handleDelete, tag}) => {
  const {getColor} = useTagColor()

  return (
    <Tooltip title={tag.description}>
      <Chip onDelete={handleDelete}
            sx={{backgroundColor : getColor(tag), color: 'white'}}
            label={tag.abbreviation}
            size="small"
      />
    </Tooltip>
  )
}

export default TagChip;