import {copyToClipboard} from "../util/Clipboard";
import {formatDate} from "../util/Dates";
import {Link} from "@mui/material";

const TradingviewLink = ({symbol, dateToCopy=null}) => {

  let link = "https://www.tradingview.com/chart/kvNjtE7Q/?symbol=" + symbol

  return <>
    <Link href={link}
       rel="noreferrer"
       onClick={(e) => {
      e.preventDefault()
      e.stopPropagation()

      copyToClipboard(formatDate(dateToCopy, 'yyyy-MM-dd'))

      window.open(link, "_tradingview")
    }} target="_blank">
      {symbol}
    </Link>
  </>
}

export default TradingviewLink;