import * as yup from "yup";
import {Form, Formik} from "formik";
import {Avatar, Box, Button, Paper} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TextField from "../components/TextField";
import bgImage from "./samurai-o.jpg"
import styled from "@emotion/styled";
import {inject, observer} from "mobx-react";
import CheckboxField from "../components/CheckboxField";

let FullscreenImage = styled.div`
  position: fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  background-image: url('${props => props.src}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover
`;

export default inject("sessionStore")(observer(({sessionStore, onSuccess}) => {

  return (
    <Formik
      validationSchema={yup.object().shape({
        username: yup.string().required(),
        password: yup.string().required()
      })}
      initialValues={sessionStore.lastCredentials || {}}
      onSubmit={(cred) => {
        sessionStore.login(cred).then(() => {
          onSuccess()
        })
      }}
    >
    <Form>

      <FullscreenImage src={bgImage} />

      <Paper style={{width:'300px', position:'fixed', top:'35%', left:'50%', transform: 'translate(-50%, -50%)', boxShadow: 'rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px'}}>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{gap: '2em'}} m={3}>

          <Avatar sx={{width: 50, height: 50}}>
            <AccountCircleIcon />
          </Avatar>

          <TextField
            label="Username"
            name="username"
          />

          <TextField
            label="Password"
            type="password"
            name="password"
          />

          <CheckboxField
            label="Remember Me"
            name="rememberMe"
          />

          <Box mt={2}>
            <Button style={{width:'200px'}} variant="contained" type="submit">Login</Button>
          </Box>

        </Box>
      </Paper>
    </Form>
  </Formik>
  )
}))

