import {useEffect} from "react";
import {inject, observer} from "mobx-react"
import {toJS} from "mobx";
import {Box, Button, Grid} from "@mui/material";
import StockAddModal from "../stock/StockAddModal";
import Table from "../components/Table";
import Pagination from "../components/Pagination";
import TradingviewLink from "../components/TradingviewLink";


export default inject("stockListStore", "stockStore")(observer(({stockListStore, stockStore}) => {

  useEffect(() => {
    stockListStore.list()
  }, []);



  const columns = [
    {
      field: 'symbol',
      headerName : "Symbol",
      width: 140,
      renderRowCell: (column, row) => {
        return <TradingviewLink symbol={row[column.field]} />
      }
    },
    {
      field: 'type.description',
      headerName : "Type"
    },
    {
      field: 'name',
      headerName : "Name"
    },
    {
      field: 'sicCode.division.description',
      headerName : "SIC Division"
    },
    {
      field: 'webUrl',
      headerName : "Url",
      type : 'url'
    },
    {
      field: 'ipo',
      headerName : "IPO",
      type : 'date'
    },
    {
      field: 'marketCap',
      headerName : "Market Cap in Mio",
      type : 'integer',
      divider : 100000
    },
    {
      field: 'sharesOutstanding',
      headerName : "Shares Outstanding in Mio",
      type : 'integer',
      divider : 100000
    },
    {
      field: 'totalEmployees',
      headerName : "Total Employees",
      type : 'integer',

    },
    {
      field: 'rsRank',
      headerName : "RsRank",
      type : 'integer',
      divider : 0.01
    },
  ]

  const clearStock = () => {
    setTimeout(() => {
      stockStore.set(null)
      stockListStore.list()
    }, 100)

  }

  const addStock = (e) => {
    stockStore.set({})
  }

  const handleRowClick = (stock) => {
    stockStore.set(stock)
  }


  return (

    <Grid>

      <h2>Stocks</h2>

      <Pagination store={stockListStore}/>
      <Table
        onRowClick={handleRowClick}
        columns={columns}
        rows={toJS(stockListStore.stocks)}
      />

      <Box justify="flex-end">
        <Button variant="outlined" onClick={addStock}>ADD</Button>
      </Box>

      {stockStore.stock &&
      <StockAddModal
        show={true}
        onSuccess={clearStock}
        stockId={stockStore.id}
        onCancel={clearStock}
        initialValues={stockStore.stock}
      />
      }

    </Grid>
  )
}))

