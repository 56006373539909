import {format as dateFormatter, parseISO} from 'date-fns';

export function formatDateTime(date, pattern = 'dd.MM.yyyy HH:mm') {
  return date ? dateFormatter(parseISO(date), pattern) : null
}

export function formatDate(date, pattern = 'dd.MM.yyyy') {
  return date ? dateFormatter(parseISO(date), pattern) : null
}

export function formatYearMonth(date, pattern = 'MMM yy') {
  return date ? dateFormatter(parseISO(date), pattern) : null
}

export function formatTime(date, pattern = 'HH:mm') {
  return date ? dateFormatter(parseISO(date), pattern) : null
}



export function now() {
  return new Date().toISOString();
}

export default formatDateTime;