import {Box, Chip, FormControl, FormHelperText, InputLabel, MenuItem, Select as MuiSelect} from "@mui/material";
import {useField} from "formik";


const SelectField = ({label, renderValueItem, multiple, options = [], name, id, ...props}) => {

  const [field, meta, helpers] = useField({name});

  const toValue = (e) => {
    if (multiple) {
      return e ? e.map(i => i.key) : [];
    }
    return e?.key
  }

  const toObject = (selected) => {
    if (multiple) {
      if (options.length === 0) {
        return null;
      }
      return selected.map(s => options.find(o => o.key === s))
    }
    return options.find(o => o.key === selected);
  }
  const handleChange = (e) => {
    helpers.setValue(toObject(e.target.value))
  }

  const renderValue = (selected) => {
    let elements = toObject(selected)
    return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {elements && elements.map((e) => (
        renderValueItem ? renderValueItem({item : e, handleDelete : null}) : <Chip key={e.key} label={e.label} />
      ))}
    </Box>
  }


  const isError = meta.touched && meta.error

  const renderValueFallback = multiple ? renderValue : null

  return (
    <FormControl variant="standard" error={isError} fullWidth>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        {...props}
        multiple={multiple}
        value={toValue(field.value)}
        id={name || id}
        onChange={handleChange}
        renderValue={renderValue ? renderValue : renderValueFallback}
      >
        {options && options.map((o) => <MenuItem value={o.key}>{o.label}</MenuItem>)}
      </MuiSelect>
      {isError &&
        <FormHelperText>{meta.error}</FormHelperText>
      }
    </FormControl>
  )
}

export default SelectField;