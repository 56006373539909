import {Dialog, Button, DialogTitle, DialogContent, DialogActions, Grid} from "@mui/material";
import {Form, Formik} from "formik";
import TextField from "../components/TextField";
import {INTEGER_FORMAT, DECIMAL_FORMAT} from "../util/Numbers";
import NumberField from "../components/NumberField";
import {validateNumber} from "../util/Validations";
import * as yup from 'yup';
import {inject, observer} from "mobx-react";
import DateTimeField from "../components/DateTimeField";

export default inject(["tradeStore"])(observer(({tradeId, show, onCancel = () => {}, onSuccess, initialValues, tradeStore}) => {

  if (!initialValues) {
    return null;
  }

  return (
    <Formik
      validationSchema={yup.object().shape({
        executionDate: yup.string().required(),
        quantity: validateNumber().required(),
        price: validateNumber().required(),
        comment: yup.string().max(1024).nullable()
      })}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(execution) => {
        const handleSuccess =  (newExecution) => onSuccess(newExecution)

        if (!execution.id) {
          tradeStore.addExecution(tradeId, execution, handleSuccess)
        } else {
          tradeStore.changeExecution(tradeId, execution.id, execution, handleSuccess);
        }
      }}
    >
      <Dialog open={show} onClose={onCancel}>

        <Form>
          <DialogTitle onClick={onCancel}>Execution</DialogTitle>

          <DialogContent>
            <Grid container spacing={3}>
              <Grid item sm={2}>
                <TextField
                  label="Typ"
                  name="type"
                  disabled={true}
                />
              </Grid>
              <Grid item sm={5}>
                <DateTimeField
                  label="Executed at"
                  name="executionDate"
                />
              </Grid>


              <Grid item sm={5}>
                {initialValues.type === 'STOP' &&
                  <DateTimeField
                    label="Expiration"
                    name="closingDate"
                  />
                }
              </Grid>


              <Grid item sm={2}>
                <NumberField
                  format={INTEGER_FORMAT}
                  label="Qty"
                  name="quantity" />

              </Grid>

              <Grid item sm={5}>
                <NumberField
                  format={DECIMAL_FORMAT}
                  label="Fill"
                  name="price" />
              </Grid>

              {initialValues.type !== 'STOP' &&
              <Grid item sm={5}>
                <NumberField
                  format={DECIMAL_FORMAT}
                  label="Create Or Update Stop"
                  name="initialStop" />
              </Grid>
              }


              <Grid item sm={12}>
                <TextField
                  label="Comment"
                  name="comment"
                  multiline
                  rows={3}
                  maxLength={1024}
                  fullWidth
                />
              </Grid>

            </Grid>

          </DialogContent>

          <DialogActions>
            <Button onClick={onCancel}>Close</Button>
            <Button type="submit">Save</Button>
          </DialogActions>
        </Form>
      </Dialog>
    </Formik>
  )
}))

