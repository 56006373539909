import {Box, Chip, Tooltip} from "@mui/material";
import useTagColor from "./useTagColor";

const TagChips = ({trade}) => {

  const {getColor} = useTagColor()

  const tagList = [...trade.buyTags, ...trade.sellTags];

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {tagList.map(tag => {
        const color = getColor(tag)
        return <Tooltip title={tag.description} key={"chip-" + trade.id + "-" + tag.id}>
          <Chip sx={{backgroundColor : color, color: 'white'}} label={tag.abbreviation} size="small"/>
        </Tooltip>
      })}
    </Box>
  )
}

export default TagChips;