import {useEffect, useState} from "react";
import {inject, observer} from "mobx-react"
import {useParams} from "react-router-dom";
import _find from "lodash/find"
import {formatDecimal, formatInteger, formatPercent} from "../util/Numbers";
import TradeAddExecutionModal from "./TradeAddExecutionModal";
import {toJS} from "mobx";
import {Box, Button, Grid, IconButton} from "@mui/material";
import Table from "../components/Table";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import EditIcon from '@mui/icons-material/Edit';
import formatDateTime, {formatTime} from "../util/Dates";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CopyableDate from "../components/CopyableDate";
import Copyable from "../components/Copyable";
import {Form, Formik} from "formik";
import AutocompleteField from "../components/AutocompleteField";
import TextField from "../components/TextField";
import SelectField from "../components/SelectField";
import TagChip from "../components/TagChip";

export default inject("tagListStore", "tradeStore", "tradeExecutionStore")(observer((props) => {

  const [tags, setTags] = useState([])
  const {id} = useParams();

  const {tradeStore, tradeExecutionStore, tagListStore} = props;

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    tradeStore.findOne(id)
    tagListStore.listAll(setTags)
  }, [id]);

  const renderExecutionCell = (callback) => (column, row) => {
    return (
      row.items
        .map(itemId => _find(tradeStore.trade?.executions, {id : itemId}))
        .sort((l,r) => l.executionDate.localeCompare(r.executionDate))
        .map((execution, index) => {
          return <Box mb={index < row.items.length - 1 ? 1 : 0}>{callback(execution)}</Box>
        })
    )
  }

  const renderExecutionType = (e) => {
    switch (e.type) {
      case 'BUY' : return <><ArrowUpwardIcon color="success" fontSize="inherit" /></>
      case 'SELL' : return <><ArrowDownwardIcon color="error" fontSize="inherit" /></>
      default : return <><FiberManualRecordOutlinedIcon color="warning" fontSize="inherit" /></>
    }
  }

  const clearTradeExecution = () => {
    tradeExecutionStore.set(null)
  }

  const addTradeExecution = (executionTemplate) => () => {
    tradeExecutionStore.create(id, executionTemplate)
  }

  const editExecution = (execution) => () => {
    tradeExecutionStore.set(execution)
  }

  const deleteExecution = (execution) => () => {
    tradeStore.deleteExecution(tradeStore.trade?.id, execution.id)
  }

  const columns = [
    {
      field: 'day',
      headerName : "Day",
      type: 'date',
      width: 120,
      renderRowCell: (column, row, rowIndex) => {
        return <CopyableDate date={row[column.field]} />
      }
    },
    {
      headerName : <AccessTimeOutlinedIcon fontSize="inherit" />,
      width: 10,
      type: 'number',
      renderRowCell : renderExecutionCell((e) => formatTime(e.executionDate))
    },
    {
      field: 'positionSize',
      headerName : "Size",
      headerCategory: 'Position',
      type: 'percent',
      width: 60,
      renderRowCell: (column, row) => {
        return formatPercent(row.positionSize, {mantissa: 0})
      }
    },
    {
      field: 'quantityBalance',
      headerName : "Qty",
      headerCategory: 'Balance',
      type: 'integer',
      width: 20,
    },
    {
      field: 'riskToAccount',
      headerName : "Account",
      headerCategory: "Risk to",
      type: 'percent',
      width: 60,
    },
    {
      field: 'riskToTrade',
      headerName : "Trade",
      headerCategory: "Risk to",
      type: 'percent',
      width: 60,
    },
    {
      field: 'risk',
      headerName : "Risk",
      headerCategory: "Abs",
      type: 'number',
      width: 60,
    },
    {
      headerName : "",
      width: 30,
      renderRowCell : renderExecutionCell(renderExecutionType)
    },
    {
      headerName : "Qty",
      headerCategory: "Trade",
      width: 10,
      type: 'number',
      renderRowCell : renderExecutionCell((e) => formatInteger(e.quantity))
    },
    {
      field: 'price',
      headerCategory: "Trade",
      headerName : "Fill",
      type: 'number',
      width: 80,
      renderRowCell : renderExecutionCell((e) => formatDecimal(e.price))
    },
    {
      field: 'amount',
      headerCategory: "Trade",
      headerName : "Amount",
      type: 'number',
      width: 80,
      renderRowCell : renderExecutionCell((e) => formatDecimal(e.amount))
    },
    {
      field: 'closingDate',
      headerCategory: "Stop",
      headerName : "Closed",
      type: 'number',
      width: 80,
      renderRowCell : renderExecutionCell((e) => <span>{formatDateTime(e.closingDate)}&nbsp;</span>)
    },
    {
      field: 'type',
      headerName : "",
      width: 20,
      renderRowCell: renderExecutionCell((e) => (
        <Box sx={{display: 'flex'}}>
          <div style={{position:'relative', height: '20px'}}>
            <IconButton size="small" onClick={editExecution(e)} style={{top: '-5px'}}>
              <EditIcon fontSize="inherit"/>
            </IconButton>
          </div>
          <div style={{position:'relative', height: '20px'}}>
            <IconButton size="small" onClick={deleteExecution(e)} style={{top: '-5px'}}>
              <DeleteOutlineIcon fontSize="inherit"/>
            </IconButton>
          </div>
        </Box>
      ))
    },
    {
      field: 'comment',
      headerName : "Comment",
      renderRowCell : renderExecutionCell((e) => <span>{e.comment}&nbsp;</span>)
    },
  ]

  if (!tradeStore.trade?.symbol) {
    return null;
  }

  const trade = toJS(tradeStore.trade)

  return (
    <Grid className="tradeList">

      <h2><Copyable displayValue={tradeStore.trade?.symbol} /> {tradeStore.trade?.direction}</h2>

      <Formik
        initialValues={trade}
        enableReinitialize={true}
        onSubmit={(trade) => {
          tradeStore.save(trade)
        }}
      >
          <Form>

            <Grid container spacing={3}>

              <Grid item sm={2}>
                <AutocompleteField
                  label="Stage"
                  name="stage"
                  getValue={(option) => option}
                  options={[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]}
                />
              </Grid>

              <Grid item sm={5}>
                <AutocompleteField
                  label="Pattern"
                  name="buyPattern"
                  getValue={(option) => option}
                  options={['T', 'C3', 'W', 'PP']}
                />
              </Grid>

              <Grid item sm={5}>
                <TextField
                  label="Pattern Description"
                  name="buyPatternInfo"
                  maxLength={100}
                />
              </Grid>

              <Grid item sm={6}>
                <SelectField
                  label="Buy Tags"
                  multiple
                  name="buyTags"
                  renderValueItem={({item, handleDelete}) => <TagChip tag={item} handleDelete={handleDelete} /> }
                  options={tags}
                />
              </Grid>

              <Grid item sm={6}>
                <SelectField
                  label="Sell Tags"
                  multiple
                  name="sellTags"
                  renderValueItem={({item, handleDelete}) => <TagChip tag={item} handleDelete={handleDelete} /> }
                  options={tags}
                />
              </Grid>


              <Grid item sm={12}>
                <TextField
                  label="Comment"
                  name="comment"
                  multiline
                  rows={3}
                  maxLength={1024}
                  fullWidth
                />
              </Grid>

              <Grid item sm={12} sx={{textAlign:'right'}}>
                <Button type="submit" variant="outlined">Save</Button>
              </Grid>

            </Grid>
          </Form>
      </Formik>

      <br />
      <br />
      <br />

      <Table
        columns={columns}
        rows={trade.clusters}
      />

      <Grid container justify="flex-end">
        <Button variant="outlined" onClick={addTradeExecution({type : 'BUY'})}>BUY</Button>
        <Button variant="outlined" onClick={addTradeExecution({type : 'STOP'})}>STOP</Button>
        <Button variant="outlined" onClick={addTradeExecution({type : 'SELL'})}>SELL</Button>
      </Grid>

      {tradeExecutionStore.execution &&
        <TradeAddExecutionModal
          show={true}
          onSuccess={clearTradeExecution}
          tradeId={id}
          direction={tradeStore.trade?.direction}
          onCancel={clearTradeExecution}
          initialValues={tradeExecutionStore.execution}
        />
      }

    </Grid>
  )
}))

