import {useEffect} from "react";
import {inject, observer} from "mobx-react"
import {toJS} from "mobx";
import {Button, Grid} from "@mui/material";
import TransactionAddModal from "../transaction/TransactionAddModal";
import Table from "../components/Table";
import {now} from "../util/Dates";
import Pagination from "../components/Pagination";


export default inject("transactionListStore", "transactionStore")(observer(({bankId, transactionListStore, transactionStore}) => {

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    transactionListStore.list(bankId)
  }, [bankId]);

  const columns = [
    {
      field: 'transactionDate',
      headerName : "Date",
      type: 'datetime',
      width: 150
    },
    {
      field: 'type',
      headerName : "Type",
      width: 100
    },
    {
      field: 'purpose',
      headerName : "Purpose",
      width: 80
    },
    {
      field: 'amount',
      headerName : "Amount",
      type: 'number',
      width: 80
    },
    {
      field: 'comment',
      headerName : "Comment"
    }
  ]

  const clearTransaction = () => {
    setTimeout(() => {
      transactionStore.set(null)
      transactionListStore.list(bankId)
    }, 100)

  }

  const addTransaction = (type) => (e) => {
    transactionStore.set({
      type : type,
      purpose : 'MANUAL',
      transactionDate: now()
    })
  }

  const handleRowClick = (transaction) => {
    if (transaction.purpose !== 'TRADE') {
      transactionStore.set(transaction)
    }
  }

  return (
    <Grid>

      <h2>Transactions</h2>

      <Pagination store={transactionListStore} options={{bankId}}/>
      <Table
        onRowClick={handleRowClick}
        columns={columns}
        rows={toJS(transactionListStore.transactions)}
      />

      <Grid container>
        <Button variant="outlined" onClick={addTransaction('DEPOSIT')}>DEPOSIT</Button>
        <Button variant="outlined" onClick={addTransaction('WITHDRAW')}>WITHDRAW</Button>
      </Grid>

      {transactionStore.transaction &&
      <TransactionAddModal
        show={true}
        onSuccess={clearTransaction}
        bankId={bankId}
        onCancel={clearTransaction}
        initialValues={transactionStore.transaction}
      />
      }

    </Grid>
  )
}))

