import {formatDate} from "../util/Dates";
import Copyable from "./Copyable";

const CopyableDate = ({date, format='yyyy-MM-dd'}) => {

  return (
    <Copyable displayValue={formatDate(date)} copyValue={formatDate(date, format)} />
  )
}

export default CopyableDate;

