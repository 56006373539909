import {action, flow, makeObservable, observable} from "mobx"
import axios from '../communication/rest'

export default class StockStore {

  stock = null;

  constructor() {
    makeObservable(this, {
      stock: observable,
      findOne: flow,
      save: flow,
      set: action
    })
  }

  *findOne(id) {
    const response = yield axios.get(`stocks/${id}`);
    this.stock = response.data;
  }

  *save(stock, callback = () => null) {
    const response = yield axios.post(`stocks`, stock);
    this.stock = response.data;
    callback(this.stock)
  }

  set(stock) {
    this.stock = stock
  }

}