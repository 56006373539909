import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import {Form, Formik} from "formik";
import TextField from "../components/TextField";
import * as yup from 'yup';
import {inject, observer} from "mobx-react";
import CheckboxField from "../components/CheckboxField";

export default inject("stockStore")(observer(({stockId, show, onCancel, onSuccess, initialValues, stockStore}) => {

  if (!initialValues) {
    return null;
  }

  return (
    <Formik
      validationSchema={yup.object().shape({
        symbol: yup.string().required(),
        sector: yup.string().max(128).nullable(),
        industry: yup.string().max(128).nullable()
      })}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(stock) => {
        stockStore.save(stock, (newStock) => {
          onSuccess(newStock)
        })
      }}
    >
      <Dialog open={show} onClose={onCancel}>

        <Form>
          <DialogTitle onClick={onCancel}>Stock</DialogTitle>

          <DialogContent>
            <Grid container spacing={3}>
              <Grid item sm={2}>
                <TextField
                  label="Symbol"
                  name="symbol"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                />
              </Grid>
              <Grid item sm={5}>
                <TextField
                  label="Sector"
                  name="sector"
                />
              </Grid>

              <Grid item sm={5}>
                <TextField
                  label="Industry"
                  name="industry"
                />
              </Grid>

            </Grid>

            <Grid container spacing={3}>
              <Grid item sm={6}>
                <CheckboxField
                  label="Enable Stock Data"
                  name="stockDataEnabled"
                />
              </Grid>


            </Grid>
          </DialogContent>

          <DialogActions>
            <Button onClick={onCancel}>Close</Button>
            <Button type="submit">Save</Button>
          </DialogActions>
        </Form>
      </Dialog>
    </Formik>
  )
}))

