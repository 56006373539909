import {flow, makeObservable, observable} from "mobx"
import axios from '../communication/rest'

export default class TradeStore {

  trade = [];

  constructor() {
    makeObservable(this, {
      trade: observable,
      findOne: flow,
      save: flow,
      addExecution: flow,
      changeExecution: flow,
      deleteExecution: flow
    })
  }

  *findOne(id) {
    const response = yield axios.get(`trades/${id}`);
    this.trade = response.data;
  }

  *save(trade, callback = () => null) {
    const response = yield axios.post(`trades${trade.id ? '/' + trade.id : ''}`, trade);
    this.trade = response.data;
    callback(this.trade)
  }

  *addExecution(tradeId, tradeExecution, callback = () => null) {
    const response = yield axios.post(`trades/${tradeId}/executions`, tradeExecution);
    this.trade = response.data;
    callback(this.trade)
  }

  *deleteExecution(tradeId, executionId, callback = () => null) {
    const response = yield axios.delete(`trades/${tradeId}/executions/${executionId}`);
    this.trade = response.data;
    callback(this.trade)
  }

  *changeExecution(tradeId, executionId, tradeExecution) {
    const response = yield axios.put(`trades/${tradeId}/executions/${executionId}`, tradeExecution);
    this.trade = response.data;
  }

}