import numbro from "numbro";
import numbroDe from "numbro/languages/de-DE";
import _isNumber from "lodash/isNumber"

numbro.registerLanguage(numbroDe)
numbro.setLanguage('de-DE')

export const INTEGER_FORMAT = {
  thousandSeparated: true,
  mantissa: 0
}

export const MONEY_FORMAT = {
  mantissa: 2,
  spaceSeparated: true,
  currencySymbol: "$",
  currencyPosition: "postfix",
  thousandSeparated: true
}

export const PERCENT_FORMAT = {
  output: 'percent',
  mantissa: 2
}


export const DECIMAL_FORMAT = {
  thousandSeparated: true,
  mantissa: 2
}

export function formatDecimal(value, format = DECIMAL_FORMAT) {
  return (value || value === 0) ? numbro(+value).format({...DECIMAL_FORMAT, ...format}) : null;
}

export function formatInteger(value, format = INTEGER_FORMAT) {
  return (value || value === 0) ? numbro(+value).format({...INTEGER_FORMAT, ...format}) : null;
}

export function formatPercent(value, format = PERCENT_FORMAT) {
  return (value || value === 0) ? numbro(+value / 100).format({...PERCENT_FORMAT, ...format}) : null;
}

export function formatMoney(value, format = MONEY_FORMAT) {
  return (value || value === 0) ? numbro(+value).formatCurrency({...MONEY_FORMAT, ...format}) : null;
}


export function isNumeric(value) {

  if (_isNumber(value)) return true;

  // https://stackoverflow.com/questions/175739/how-can-i-check-if-a-string-is-a-valid-number
  if (typeof value != "string") return false // we only process strings!
  return !isNaN(value) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(value)) // ...and ensure strings of whitespace fail
}

export function unformat(value) {
  //numbro.setLanguage("en-GB")
  value = (value + "").replace(/[^0-9.,+-]/g, '')
  return numbro.unformat(value);
}

