import {Autocomplete as MuiAutocomplete} from "@mui/material";
import {TextField} from "@mui/material";
import {useField} from "formik";


const AutocompleteField = ({id, label, className, name, inputProps, getValue, ...props}) => {

  const [field, meta, helpers] = useField({name});

  const handleChange = (e, newValue) => {
    helpers.setValue(getValue ? getValue(newValue) : newValue[name])
  }

  return (
    <MuiAutocomplete
      {...props}
      value={field.value}
      onChange={handleChange}
      onBlur={field.onBlur}

      renderInput={(params) => {
        return <TextField
          {...field}
          {...params}
          inputProps={{
            ...params.inputProps,
            ...inputProps
          }}
          className={className}
          id={id}
          name={name}
          type="text"
          variant="standard"
          label={label}
          error={meta.touched && meta.error}
          helperText={meta.touched && meta.error}
        />
      }}
    />
  )
}

export default AutocompleteField;