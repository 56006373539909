import TradeListStore from "./tradelist/TradeListStore";
import TransactionStore from "./transaction/TransactionStore";
import TransactionListStore from "./transactionlist/TransactionListStore";
import TradeStore from "./trade/TradeStore";
import SummaryStore from "./summary/SummaryStore";
import TradeExecutionStore from "./trade/TradeExecutionStore";
import StockStore from "./stock/StockStore";
import StockListStore from "./stocklist/StockListStore";
import SessionStore from "./session/SessionStore";
import TagStore from "./tag/TagStore";
import TagListStore from "./taglist/TagListStore";
import StockDataStore from "./stockdata/StockDataStore";

let sessionStore = new SessionStore()
let tradeListStore = new TradeListStore()
let transactionStore = new TransactionStore()
let transactionListStore = new TransactionListStore()
let tradeStore = new TradeStore()
let summaryStore = new SummaryStore({sessionStore})
let tradeExecutionStore = new TradeExecutionStore()
let stockStore = new StockStore()
let stockListStore = new StockListStore()
let tagStore = new TagStore()
let tagListStore = new TagListStore()
let stockDataStore = new StockDataStore()

let store = {
  sessionStore,
  tradeListStore,
  transactionStore,
  transactionListStore,
  tradeStore,
  summaryStore,
  tradeExecutionStore,
  stockStore,
  stockListStore,
  tagStore,
  tagListStore,
  stockDataStore
}

export default store;