import {inject, observer} from "mobx-react";
import {useEffect} from "react";
import Table from "../components/Table";
import {formatYearMonth} from "../util/Dates";
import {formatDecimal, formatInteger, formatMoney, formatPercent, isNumeric} from "../util/Numbers";
import {Box} from "@mui/material";

export default inject("summaryStore")(observer(({summaryStore}) => {

  useEffect(() => {
    summaryStore.get()
  }, []);

  const {summary} = summaryStore;

  if (summary == null) {
    return null;
  }

  const buildColumns = () => {

    const columns = Object.keys(summary.performance).map(yearmonth => {
      return {
        headerName: formatYearMonth(yearmonth),
        field: yearmonth,
        type: 'percent'
      }
    })

    columns.splice(0, 0, {headerName: "", field: 'name'})
    return columns
  }

  const renderIntCell = (column, row) => {
    let value = row[column.field]
    return isNumeric(value) ? formatInteger(value) : value
  }

  const renderDecimalCell = (column, row) => {
    let value = row[column.field]
    return isNumeric(value) ? formatDecimal(value) : value
  }

  const renderPercentCell = (column, row) => {
    let value = row[column.field]
    return isNumeric(value) ? formatPercent(value) : value
  }

  const renderMoneyCell = (column, row) => {
    let value = row[column.field]
    return isNumeric(value) ? formatMoney(value) : value
  }

  const buildRows = () => {

    let rows = []
    rows.push(Object.assign({name : 'Performance'}, summary.performance))
    rows.push(Object.assign({name : 'Balance', renderRowCell : renderMoneyCell}, summary.bankBalance))
    rows.push(Object.assign({name : 'Manual', renderRowCell : renderMoneyCell}, summary.bankManualDeposit))
    rows.push(Object.assign({name : 'Batting Avg'}, summary.battingAverage))
    rows.push(Object.assign({name : 'Risk/Reward', renderRowCell : renderDecimalCell}, summary.riskReward))
    rows.push(Object.assign({name : 'Kelly', renderRowCell : renderDecimalCell}, summary.kellyPositionSize))
    rows.push(Object.assign({name : 'Avg % Win', renderRowCell : renderPercentCell}, summary.averageWinPerc))
    rows.push(Object.assign({name : 'Avg % Loss', renderRowCell : renderPercentCell}, summary.averageLossPerc))
    rows.push(Object.assign({name : 'Avg $ Win', renderRowCell : renderMoneyCell}, summary.averageWin))
    rows.push(Object.assign({name : 'Avg $ Loss', renderRowCell : renderMoneyCell}, summary.averageLoss))
    rows.push(Object.assign({name : 'Largest Win', renderRowCell : renderMoneyCell}, summary.largestWin))
    rows.push(Object.assign({name : 'Largest Loss', renderRowCell : renderMoneyCell}, summary.largestLoss))
    rows.push(Object.assign({name : 'Trade Count', renderRowCell : renderIntCell}, summary.count))
    rows.push(Object.assign({name : 'Wins', renderRowCell : renderIntCell}, summary.wins))
    rows.push(Object.assign({name : 'Losses', renderRowCell : renderIntCell}, summary.losses))
    rows.push(Object.assign({name : 'Cur. Strike', renderRowCell : renderIntCell}, summary.currentStrike))
    rows.push(Object.assign({name : 'Strike Win', renderRowCell : renderIntCell}, summary.maxWinningStrike))
    rows.push(Object.assign({name : 'Strike Loss', renderRowCell : renderIntCell}, summary.maxLoosingStrike))
    rows.push(Object.assign({name : 'Hold Wins', renderRowCell : renderDecimalCell}, summary.holdingWinDays))
    rows.push(Object.assign({name : 'Hold Losses', renderRowCell : renderDecimalCell}, summary.holdingLossDays))
    return rows
  }

  return <Box mb={10}>
    <h2>Summary</h2>

    <Table
      size="small"
      columns={buildColumns()}
      rows={buildRows()}
      firstColumnSticky={true}
    />
  </Box>
}))