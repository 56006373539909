import {
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import * as Numbers from "../util/Numbers";
import * as Dates from "../util/Dates";
import _get from "lodash/get"
import CheckIcon from "@mui/icons-material/Check";
import PropTypes from "prop-types";

const Table = ({columns, rows, onRowClick = () => {}, firstColumnSticky = false, ...props}) => {

  if (!rows || rows.length === 0) {
    return null
  }

  const renderCellByType = (column, row, rowIndex) => {

    let value = _get(row, column.field)
    let title = _get(row, column.title, '')

    switch (column.type) {
      case 'number' : return Numbers.formatDecimal(value)
      case 'percent' : return Numbers.formatPercent(value)
      case 'money' : return Numbers.formatMoney(value)
      case 'integer' : return value ? Numbers.formatInteger(value / (column.divider ? column.divider : 1)) : null
      case 'datetime' : return Dates.formatDateTime(value)
      case 'date' : return Dates.formatDate(value)
      case 'url' : return <a href={value} target="details">{value}</a>
      case 'boolean' : return value ? <CheckIcon fontSize="inherit"/> : null
      case 'index' : return rowIndex + 1
      default : return <span title={title}>{value}</span>
    }
  }

  const getPropsForCell = (column, row) => {

    let isNumber = column.type === 'number' || column.type === 'percent' || column.type === 'money' || column.type === 'integer'
    let isNoBreak = column.type === 'datetime' || column.type === 'date'

    // TODO refactor constants
    let getColor = v => v >= 0 ? "#2e7d32" : "#d32f2f"
    let fontColor = row && isNumber && column.attributes && column.attributes.includes("colored") ? getColor(row[column.field]) : "inherited"

    return {
      sx: {
        whiteSpace: isNumber || isNoBreak ? 'nowrap' : 'normal',
        color: fontColor,
      },
      className: "success",
      width: column.width,
      align: isNumber ? 'right' : 'left'
    }
  }

  const renderColumnCell = (col, index) => {
    const headerProps = { ...getPropsForCell(col) }
    return (
      <TableCell {...headerProps} key={`col-${index}`}>
        <Typography>
          <small>{col.headerCategory}</small><br />
        </Typography>
        {col.renderColumnCell ? col.renderColumnCell(col) : col.headerName}
      </TableCell>
    )
  }

  const renderRowCell = (row, rowIndex) => (column, colIndex) => {

    let style = {
      verticalAlign: 'top'
    }

    if (colIndex === 0 && firstColumnSticky) {
      style = Object.assign({
        position: 'sticky',
        left:'0',
        backgroundColor:'white'
      }, style)
    }

    return (
      <TableCell {...getPropsForCell(column, row)} style={style} key={`row-${rowIndex}-col-${colIndex}`}>
        {column.renderRowCell ? column.renderRowCell(column, row, rowIndex) : row.renderRowCell ? row.renderRowCell(column, row, rowIndex) : renderCellByType(column, row, rowIndex)}
      </TableCell>
    )
  }

  const renderRow = (row, index) => {
    return (
      <TableRow onClick={() => onRowClick(row)} key={`row-${index}`} hover>
        {columns.map(renderRowCell(row, index))}
      </TableRow>
    )
  }


  return (
    <TableContainer component={Paper}>
      <MuiTable aria-label="simple table" {...props} size="small">
        <TableHead>
          <TableRow hover>
            {columns.map(renderColumnCell)}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows && rows.map(renderRow)}
        </TableBody>
      </MuiTable>
    </TableContainer>
  )
}

Table.propTypes = {
  firstColumnSticky: PropTypes.bool
}

export default Table;