import {useTheme} from "@mui/material";
import _get from "lodash/get"

const colors = {
  'BEST' : 'success.dark',
  'BETTER' : 'success.main',
  'GOOD' : 'success.light',
  'NEUTRAL' : 'info.main',
  'BAD' : 'error.light',
  'WORSE' : 'error.main',
  'WORST' : 'error.dark',
}

const useTagColor = () => {
  const theme = useTheme();

  const getColor = (tag) => {
    return _get(theme.palette, colors[tag.evaluationType])
  }

  return {getColor}
}

export default useTagColor;