import Navbar from './menu/Navbar'
import {Navigate, Outlet, Route, Routes, useNavigate} from "react-router-dom";
import TradeList from "./tradelist/TradeList";
import Trade from "./trade/Trade";
import "typeface-roboto";
import StockList from "./stocklist/StockList";
import TransactionList from "./transactionlist/TransactionList";
import {Backdrop, Box, CircularProgress} from "@mui/material";
import Summary from "./summary/Summary";
import Login from "./login/Login";
import {inject, observer} from "mobx-react";
import TagList from "./taglist/TagList";
import StockDataList from "./stockdata/StockDataList";

export default inject(["sessionStore"])(observer(({sessionStore, ...rest}) => {

  const navigate = useNavigate();

  if (sessionStore.loading) {
    return <Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}>
      <CircularProgress color="primary"/>
    </Backdrop>
  }

  return (
    <div className="App">

      {sessionStore.session &&
        <Navbar />
      }

      <Box m={2}>
        <Routes>
          <Route path="/" element={sessionStore.session ? <Outlet /> : <Navigate to="/login" />}>
            <Route path="/tags" element={<TagList />} />
            <Route path="/summary" element={<Summary />} />
            <Route path="/trades" element={<TradeList />} />
            <Route path="/rs/stocks" element={<StockDataList endpoint="stocks" />} />
            <Route path="/rs/sectors" element={<StockDataList endpoint="sectors" />} />
            <Route path="/uptrending" element={<StockDataList endpoint="uptrending" />} />
            <Route path="/stocks" element={<StockList />} />
            <Route path="/transactions" element={<TransactionList bankId={sessionStore.session?.bankId} />} />
            <Route path="/trades/:id" element={<Trade />} />
          </Route>
          <Route path="/login" element={<Login onSuccess={() => navigate('/trades')}/>} />
          <Route path="*" element={<Navigate to="/trades" />} />
        </Routes>
      </Box>
    </div>
  );
}))


