import {IconButton} from "@mui/material";
import CopyContentIcon from "@mui/icons-material/ContentCopy";
import {copyToClipboard} from "../util/Clipboard";
import {toast} from "react-toastify";

const Copyable = ({displayValue, copyValue}) => {

  const handleCopyToClipboard = (e) => {
    e.stopPropagation()

    let v = copyValue ? copyValue : displayValue
    copyToClipboard(v)
    toast.success("Copy value " + v)
  }

  return (
    <>
      {displayValue}&nbsp;
      <div style={{display:'inline-block'}}>
        <IconButton size="small" edge="end" onClick={handleCopyToClipboard} sx={{padding:'0', transform: 'translateY(-8%)'}}>
          <CopyContentIcon fontSize="inherit"/>
        </IconButton>
      </div>
    </>
  )
}

export default Copyable;

