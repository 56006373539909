import {FormControl, Grid, InputLabel, MenuItem, Pagination as MuiPagination, Select} from "@mui/material";
import {toJS} from "mobx";

const Pagination = ({store, options}) => {

  const page = toJS(store.page);

  if (!page) {
    return null
  }


  const pageNumber = page.number + 1

  const onPageChange = (pageNumber, pageSize) => {
    store.changePage({
      page: pageNumber - 1,
      size: pageSize,

    }, options)
  }

  return <>
    <Grid container spacing={2}>
      <Grid item style={{ flexGrow: "1" }}>
        <MuiPagination
          count={page.totalPages}
          size={"small"}
          page={pageNumber}
          onChange={(e, newNumber) => onPageChange(newNumber, page.size)}
          color="primary"
        />
      </Grid>

      <Grid item>
        <FormControl size="small">
          <InputLabel>Size</InputLabel>
          <Select sx={{minWidth: '80px'}}
                  size={"small"}
                  value={page.size}
                  defaultValue={null}
                  label="Size"
                  onChange={(e) => {
                    onPageChange(pageNumber, e.target.value)
                  }}
          >
            {[10, 20, 50, 100, 200].map(i => {
              return <MenuItem value={i} key={"pagination-" + i}>{i}</MenuItem>
            })}
          </Select>
        </FormControl>
      </Grid>

    </Grid>
  </>

}

export default Pagination;