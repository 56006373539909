import {flow, makeObservable, observable, action} from "mobx"
import axios from '../communication/rest'

export default class TradeExecutionStore {

  execution = null;

  constructor() {
    makeObservable(this, {
      execution: observable,
      create: flow,
      set: action
    })
  }

  *create(tradeId, executionTemplate) {
    const response = yield axios.get(`trades/${tradeId}/executions/0`, {params : executionTemplate});
    this.execution = response.data;
  }

  set(execution) {
    this.execution = execution
  }

}