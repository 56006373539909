import {useState} from "react";
import {formatDecimal, isNumeric, unformat} from "../util/Numbers";
import {TextField} from "@mui/material";
import {useField} from "formik";

const NumberField = ({id, label, className, name, format = {mantissa : 2}}) => {

  const [field, meta, helpers] = useField({name});

  const {onChange, onBlur, value} = field

  const [focus, setFocus] = useState(false)

  const getValue = () => {

    if (!isNumeric(value) || focus || meta.error) {
      return value
    }

    return formatDecimal(value, {...format, thousandSeparated : true})
  }

  const handleBlur = (e) => {
    setFocus(false);
    onBlur(e)

    if (value == null || value === "") {
      helpers.setValue("");
    } else if (isNumeric(value)) {
      helpers.setValue(+value)
    } else {
      let newValue = unformat(value);
      if (isNumeric(newValue)) {
        helpers.setValue(newValue);
      }
    }
  }

  const onPaste = (e) => {
    let content = e.clipboardData.getData("text")
    if (content && content.startsWith('$')) {

      let usNumber = +content.replaceAll(/[^0-9.+-]/g, '')
      if (isNumeric(usNumber)) {
        e.preventDefault()
        helpers.setValue(usNumber);
        setFocus(true)
      }
    }
  }

  return (
    <TextField
      id={id}
      name={name}
      error={meta.touched && meta.error}
      helperText={meta.touched && meta.error}
      variant="standard"
      className={className}
      type="text"
      label={label}
      onChange={onChange}
      value={getValue()}
      onFocus={() => setFocus(true)}
      onBlur={handleBlur}
      onPaste={onPaste}
      autoComplete="off"
    />
  )
}

export default NumberField;