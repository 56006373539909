import * as yup from "yup"
import {de} from 'yup-locales';
import {isNumeric} from "./Numbers";

yup.setLocale(de);

const GERMAN_NUMBER_FORMAT = /^[+-]?(\d*)(\.\d{3})*(,\d*)?$/

const NUMBER_CHARS = /^[0-9,.+-]*$/

export function shape(object) {
  return yup.object.shape(object)
}

export function validateNumber() {
  return yup.number().transform((_, value) => {

    if (value === null || value === "") {
      return null;
    }

    if (isNumeric(value)) {
      return +value;
    }

    if (!NUMBER_CHARS.test(value)) {
      return NaN;
    }

    if (GERMAN_NUMBER_FORMAT.test(value)) {
      value = value.replace(/\./, '');
      value = value.replace(/,/, '.');
    } else {
      value = value.replace(/,/, '');
    }

    return isNumeric(value) ? +value : NaN;
  });
}