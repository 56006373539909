import {action, flow, makeObservable, observable} from "mobx"
import axios from '../communication/rest'

export default class TagStore {

  tag = null;

  constructor() {
    makeObservable(this, {
      tag: observable,
      findOne: flow,
      save: flow,
      set: action
    })
  }

  *findOne(id) {
    const response = yield axios.get(`tags/${id}`);
    this.stock = response.data;
  }

  *save(tag, callback = () => null) {
    const response = yield axios.post(`tags`, tag);
    this.tag = response.data;
    callback(this.tag)
  }

  set(tag) {
    this.tag = tag
  }

}