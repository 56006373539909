import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import {Form, Formik} from "formik";
import TextField from "../components/TextField";
import {DECIMAL_FORMAT} from "../util/Numbers";
import NumberField from "../components/NumberField";
import {validateNumber} from "../util/Validations";
import * as yup from 'yup';
import {inject, observer} from "mobx-react";
import DateTimeField from "../components/DateTimeField";

export default inject("transactionStore")(observer(({bankId, show, onCancel, onSuccess, initialValues, transactionStore}) => {

  if (!initialValues) {
    return null;
  }

  return (
    <Formik
      validationSchema={yup.object().shape({
        transactionDate: yup.string().required(),
        amount: validateNumber().required()
      })}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(transaction) => {
        transactionStore.save(bankId, transaction, (newTransaction) => {
          onSuccess(newTransaction)
        })
      }}
    >
      <Dialog open={show} onClose={onCancel}>

        <Form>
          <DialogTitle onClick={onCancel}>Transaction</DialogTitle>

          <DialogContent>
            <Grid container spacing={3}>
              <Grid item sm={2}>
                <TextField
                  label="Typ"
                  name="type"
                  disabled={true}
                />
              </Grid>

              <Grid item sm={2}>
                <TextField
                  label="Purpose"
                  name="purpose"
                  disabled={true}
                />
              </Grid>

              <Grid item sm={4}>
                <DateTimeField
                  label="Date"
                  name="transactionDate"
                />
              </Grid>

              <Grid item sm={4}>
                <NumberField
                  format={DECIMAL_FORMAT}
                  label="Amount"
                  name="amount" />

              </Grid>

              <Grid item sm={12}>
                <TextField
                  label="Comment"
                  name="comment"
                  multiline
                  rows={3}
                  maxLength={1024}
                  fullWidth
                />
              </Grid>
            </Grid>

          </DialogContent>

          <DialogActions>
            <Button onClick={onCancel}>Close</Button>
            <Button type="submit">Save</Button>
          </DialogActions>
        </Form>
      </Dialog>
    </Formik>
  )
}))

